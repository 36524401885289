import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Mutation type represents all the mutable entry points to the API.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> Mutation <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Create new location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returns created location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">locationCreate</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Batch input for location creation.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">config</GQLAttribute>: <GQLLink href="/ecdn-api-input-objects/BatchLocationInput" mdxType="GQLLink">BatchLocationInput</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Batch configuration of a location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returns configured location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">locationConfigure</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of location to configure.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">locationID</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Batch input for location configuration.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">config</GQLAttribute>: <GQLLink href="/ecdn-api-input-objects/BatchLocationInput" mdxType="GQLLink">BatchLocationInput</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Set a common parent for locations. Parent can either be a region or a location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">locationSetParents</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of (children) locations (which we set a parent for).</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">locationIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of parent location/region.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">parentID</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Delete locations.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">locationDelete</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of locations to delete.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">locationIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Create new server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returns created server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverCreate</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Batch input for server creation.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">config</GQLAttribute>: <GQLLink href="/ecdn-api-input-objects/BatchServerInput" mdxType="GQLLink">BatchServerInput</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Server" mdxType="GQLLink">Server</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Batch configuration of a server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returns configured server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverConfigure</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of server to configure.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverID</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Selected mode to apply this configuration to an ECDN server.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">mode</GQLAttribute>: <GQLLink href="/ecdn-api-enums/ConfigurationMode" mdxType="GQLLink">ConfigurationMode</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Batch input for server configuration.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">config</GQLAttribute>: <GQLLink href="/ecdn-api-input-objects/BatchServerInput" mdxType="GQLLink">BatchServerInput</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Server" mdxType="GQLLink">Server</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Cancels PENDING desired MANUAL configuration of a server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverConfigurationCancel</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of server to cancel PENDING MANUAL configuration on.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverID</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Enable content delivery on servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverEnableContentDelivery</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of servers to enable content delivery on.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Disable content delivery on servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverDisableContentDelivery</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of servers to disable content delivery on.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Enable remote assistance on servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverEnableRemoteAssistance</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of servers to enable remote assistance on.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Disable remote assistance on servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverDisableRemoteAssistance</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of servers to disable remote assistance on.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Delete servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverDelete</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># IDs of servers to delete.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">serverIDs</GQLAttribute>: [<GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-scalars/Void" mdxType="GQLLink">Void</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Create time-series report of ECDN video delivery.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Interval of a query with minutely granularity cannot exceed 24 hours.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Metrics older than 1 year are discarded.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returns created report.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">reportCreate</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Granularity of time intervals.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">granularity</GQLAttribute>: <GQLLink href="/ecdn-api-enums/Granularity" mdxType="GQLLink">Granularity</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Break down report's intervals by additional fields.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">breakdown</GQLAttribute>: <GQLLink href="/ecdn-api-enums/ReportBreakdown" mdxType="GQLLink">ReportBreakdown</GQLLink></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Start report from this point in time.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">from</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/Time" mdxType="GQLLink">Time</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># End report at this point in time.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">to</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/Time" mdxType="GQLLink">Time</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Send created report to this email address.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">email</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/EmailAddress" mdxType="GQLLink">EmailAddress</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Included fields in the report.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">include</GQLAttribute>: [<GQLLink href="/ecdn-api-enums/ReportField" mdxType="GQLLink">ReportField</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Report" mdxType="GQLLink">Report</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Add SSH key.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Authorization: must be at least ADMIN.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returns added SSH key.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">sshKeyAdd</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Description of SSH key.</GQLComment></GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Must not be empty, must be unique and maximum allowed length is 60 characters.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">description</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Public part of SSH key. It can either be:</GQLComment></GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * RSA: must be at least 2048 bits long.</GQLComment></GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * ECDSA: 256, 384, or 521 bits long.</GQLComment></GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * Ed25519.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">publicKey</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/PublicKey" mdxType="GQLLink">PublicKey</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/SSHKey" mdxType="GQLLink">SSHKey</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`locationCreate: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Location"
      }}>{`Location`}</a>{`!`}</h3>
    <p>{`Create new location.
Authorization: must be at least ADMIN.
Returns created location.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-input-objects/BatchLocationInput"
            }}>{`BatchLocationInput`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Batch input for location creation.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`locationConfigure: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Location"
      }}>{`Location`}</a>{`!`}</h3>
    <p>{`Batch configuration of a location.
Authorization: must be at least ADMIN.
Returns configured location.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`locationID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of location to configure.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-input-objects/BatchLocationInput"
            }}>{`BatchLocationInput`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Batch input for location configuration.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`locationSetParents: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Set a common parent for locations. Parent can either be a region or a location.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`locationIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of (children) locations (which we set a parent for).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`parentID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of parent location/region.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`locationDelete: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Delete locations.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`locationIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of locations to delete.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverCreate: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Server"
      }}>{`Server`}</a>{`!`}</h3>
    <p>{`Create new server.
Authorization: must be at least ADMIN.
Returns created server.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-input-objects/BatchServerInput"
            }}>{`BatchServerInput`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Batch input for server creation.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverConfigure: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Server"
      }}>{`Server`}</a>{`!`}</h3>
    <p>{`Batch configuration of a server.
Authorization: must be at least ADMIN.
Returns configured server.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of server to configure.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-enums/ConfigurationMode"
            }}>{`ConfigurationMode`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selected mode to apply this configuration to an ECDN server.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-input-objects/BatchServerInput"
            }}>{`BatchServerInput`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Batch input for server configuration.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverConfigurationCancel: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Cancels PENDING desired MANUAL configuration of a server.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of server to cancel PENDING MANUAL configuration on.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverEnableContentDelivery: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Enable content delivery on servers.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of servers to enable content delivery on.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverDisableContentDelivery: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Disable content delivery on servers.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of servers to disable content delivery on.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverEnableRemoteAssistance: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Enable remote assistance on servers.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of servers to enable remote assistance on.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverDisableRemoteAssistance: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Disable remote assistance on servers.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of servers to disable remote assistance on.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`serverDelete: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Void"
      }}>{`Void`}</a></h3>
    <p>{`Delete servers.
Authorization: must be at least ADMIN.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`serverIDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IDs of servers to delete.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`reportCreate: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Report"
      }}>{`Report`}</a>{`!`}</h3>
    <p>{`Create time-series report of ECDN video delivery.
Interval of a query with minutely granularity cannot exceed 24 hours.
Metrics older than 1 year are discarded.
Returns created report.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`granularity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-enums/Granularity"
            }}>{`Granularity`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Granularity of time intervals.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`breakdown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-enums/ReportBreakdown"
            }}>{`ReportBreakdown`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Break down report’s intervals by additional fields.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`from`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/Time"
            }}>{`Time`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start report from this point in time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/Time"
            }}>{`Time`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End report at this point in time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/EmailAddress"
            }}>{`EmailAddress`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Send created report to this email address.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`include`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-enums/ReportField"
            }}>{`ReportField`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Included fields in the report.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`sshKeyAdd: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/SSHKey"
      }}>{`SSHKey`}</a>{`!`}</h3>
    <p>{`Add SSH key.
Authorization: must be at least ADMIN.
Returns added SSH key.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/String"
            }}>{`String`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of SSH key.`}<br />{`Must not be empty, must be unique and maximum allowed length is 60 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`publicKey`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/PublicKey"
            }}>{`PublicKey`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Public part of SSH key. It can either be:`}<br /><em parentName="td">{` RSA: must be at least 2048 bits long.`}<br /></em>{` ECDSA: 256, 384, or 521 bits long.`}<br />{`* Ed25519.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      